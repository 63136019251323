<template>
  <div>
    <!-- Title and Controls-->
    <div class="d-flex justify-space-between">

      <div class="d-flex align-center mb-5 ">
        <v-icon :color="wsACCENT">mdi-menu-{{ displayContent ? 'down' : 'right' }}</v-icon>
        <h4 @click="expand" class="wsDARKER linkHover pointer">
          {{ $t('wsu_structure.specialty.faq.title') }}
        </h4>
      </div>


      <accordion-controls-menu
          v-if="displayContent"
          type="faq"
          :uuid="value.enroll_faq_id"
          :content="value.enroll_faq_content"
          :lang="$store.state.wsu_structure.selectedLang"
          :update="updateEntity"
      />

    </div>

    <div v-if="displayContent">
      <description-items-editor
          v-if="value.enroll_faq_id"
          :value="value.enroll_faq_content"
          :template-name="value.enroll_faq_template_name"
          @input="updateContent"
          confirm-delete-text="wsu_structure.specialty.enroll_faq.delete"
          :add-item-trigger="addItemTrigger"
      >
        <!--    :blank-element="blankElement"-->
      </description-items-editor>
      <div v-else style="background-color: var(--wsLIGHTCARD);" class="wsRoundedLight pa-6 py-12">
        <h4 class="text-center wsACCENT">Не має запитань</h4>
        <h5 class="text-center font-weight-regular wsACCENT mt-2">Оберіть шаблон частих запитань або створіть новий</h5>

        <div class="d-flex align-center justify-center mt-5">
          <ws-button label="Choose" outlined icon="mdi-menu-down" />
          <ws-button @click="createNewAccordion" label="Create" class="ml-2" />
        </div>

      </div>
    </div>

  </div>



</template>

<script>
import descriptionItemsEditor from "@modules/wsu_structure/components/description/descriptionItemsEditor.vue";
import accordionControlsMenu from "@modules/wsu_structure/components/description/accordionControlsMenu.vue";
import {mapActions} from "vuex";
export default {
  name: "descriptionSpecialtyFAQ",
  components: {
    accordionControlsMenu,
    descriptionItemsEditor
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
  },
  data() {
    return {
      displayContent : false,
      addItemTrigger : 0,
      blankElement : {
        name : '',
        position : '',
        short_description : '',
        description : [{ isHtml: true, content: ''}],
        img : ''
      }
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value, this.items)) {
          this.items = this.COPY(this.value)
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('wsu_structure',['ADD_SPECIALTY_ACCORDION']),

    expand() {
      this.displayContent = !this.displayContent
      this.$emit('expand', this.displayContent)
    },

    async createNewAccordion() {
      let data = {
        specialty_id : this.value.uuid,
        lang : this.$store.state.wsu_structure.selectedLang,
        type : 'enroll_faq'
      }
      let result = await this.ADD_SPECIALTY_ACCORDION(data)
      this.updateEntity(result)
      setTimeout(()=> {
        this.addItemTrigger++
      },100)
    },

    updateEntity(data) {
      this.$emit('input', this.COPY(data))
    },
    updateContent(item) {
      let data = this.COPY(this.value)
      data.enroll_faq_content = item
      this.$emit('input', this.COPY(data))
    }
  },
  mounted() {
    if (this.value) {
      this.items = this.COPY(this.value)
    }
  }
}
</script>


<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>