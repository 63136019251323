<template>
<div>
  <!-- DROPDOWN BUTTON -->
  <ft-select
      v-if="value.type"
      :items="editActionsSelectFiltered"
  >
    <v-icon :color="wsACCENT">mdi-dots-horizontal</v-icon>
  </ft-select>

  <!-- DIALOGS -->

  <!-- Save As Template Dialog-->
  <ws-dialog
      v-if="displaySaveTemplateDialog"
      v-model="displaySaveTemplateDialog"
      :title="$t('SaveAsTemplate')"
      @save="saveAsTemplate"
  >

    <h4 class="wsACCENT font-weight-regular">
      {{ $t('wsu_structure.specialty.accordion.save_as_template.description') }}
    </h4>

    <ws-text-field
        v-model="entityData.name"
        class="mt-8"
        :label="$t('TemplateName')"
        :placeholder="$t('EnterTemplateName')"
        :error="nameError"
        @input="nameError = false"
    />

    <ws-text-field
        v-model="entityData.description"
        class="mt-5"
        :label="$t('TemplateDescription')"
        :placeholder="$t('EnterTemplateDescription')"
        area
    />


  </ws-dialog>

  <!-- Enable Edit Template Dialog-->
  <ws-dialog
      v-if="displayEnableEditDialog"
      v-model="displayEnableEditDialog"
      @save="enableEditTemplate"
      @click="enableEditTemplate"
      :title="$t('wsu_structure.specialty.accordion.enable_edit_template.title')"
      :save-text="$t('Proceed')"
      :loading="LOADING"

  >

    <h4 class="wsACCENT font-weight-regular">{{ $t('wsu_structure.specialty.accordion.enable_edit_template.description') }}</h4>

    <v-sheet :color="wsLIGHTCARD" class="wsRoundedSemi mt-5 pa-3 d-flex align-center">
      <v-icon :color="wsACCENT" class="mr-3">mdi-information</v-icon>
      <div>
        <h5 class="wsACCENT font-weight-regular ">{{ $t('wsu_structure.specialty.accordion.enable_edit_template.tooltip') }}</h5>
        <h5 @click="openEditTemplate" class="wsACCENT linkHover pointer">
          {{ $t('wsu_structure.specialty.accordion.enable_edit_template.goto_edit') }}
        </h5>
      </div>
    </v-sheet>


  </ws-dialog>

  <!-- Select Template Dialog-->
  <ws-dialog
      v-if="displaySelectDialog"
      v-model="displaySelectDialog"
      :title="$t('ChooseTemplate')"
      hide-save
  >

      <ws-text-field
          v-model="templatesSearch"
          icon="mdi-magnify"
          :placeholder="$t('Search')"
      />
      <ws-data-table
          :headers="templateHeaders"
          :items="templatesSelect"
          :search="templatesSearch"
          :row-action="selectTemplate"

      >
        <template #item.text="{item}">
          <h4 class="wsDARKER">{{ item.text }}</h4>
          <h5 class="wsDARKER font-weight-regular">{{ item.description }}</h5>
        </template>
      </ws-data-table>



  </ws-dialog>

  <!-- Delete Template Dialog-->
  <ws-dialog
      v-if="displayDeleteDialog"
      v-model="displayDeleteDialog"
      :title="$t('wsu_structure.specialty.accordion.delete_template.title')"
  >

    <h1>Delete Template Dialog</h1>

  </ws-dialog>


</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "accordionControlsMenu",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    type : {
      type : String,
    },
    lang : {
      type : String,
    },
    content : {
      type : Array,
      default() { return [] }
    },
    editTemplateTrigger : {
      type : Number
    },
    enableEditTrigger : {
      type : Number
    },
    specialtyId : {
      type : String,
    },
    chooseTemplateTrigger : {
      type : Number
    },

  },
  data() {
    return {
      nameError : false,
      entityData : {},
      displaySaveTemplateDialog : false,
      displaySelectDialog : false,
      displayDeleteDialog : false,
      displayEnableEditDialog : false,
      templatesSelect : [],
      templatesSearch : '',
      previewTemplateId : null,
      previewTemplate : {}
    }
  },
  computed : {
    templateHeaders() {
      return [
        { text : this.$t('Template') , value : 'text' }
      ]
    },
    editActionsSelectFiltered() {
      return this.editActionsSelect.filter(el => !el.hidden)
    },
    editActionsSelect() {
      return [
        { text : this.$t('EditTemplate'),
          icon : 'mdi-book-edit-outline',
          action : this.openEditTemplate,
          hidden : !this.value.is_template
        },
        { text : this.$t('wsu_structure.specialty.accordion.enable_edit_template.title'),
          icon : 'mdi-pencil',
          action : this.openEnableEdit,
          hidden : !this.value.is_template
        },
        { text : this.$t('SaveAsTemplate'),
          icon : 'mdi-content-save-edit-outline',
          action : this.openSaveTemplate,
          hidden : this.value.is_template
        },
        { text : this.$t('ChooseTemplate'),
          icon : 'mdi-folder-open-outline ',
          action : this.openChangeTemplate
        },
        { text : this.$t('Delete'),
          icon : 'mdi-delete-outline',
          textColor : this.wsWARNING,
          iconColor : this.wsWARNING,
          action : this.openDeleteAccordion
        }
      ]
    }
  },
  watch : {
    chooseTemplateTrigger() {
      this.openChangeTemplate()
    },
    editTemplateTrigger() {
      this.openEditTemplate()
    },
    enableEditTrigger() {
      this.openEnableEdit()
    },
  },
  methods : {
    ...mapActions('wsu_structure', [
        'ADD_SPECIALTY_ACCORDION_TEMPLATE',
        'GET_SPECIALTY_ACCORDION_TEMPLATES',
        'SET_SPECIALTY_ACCORDION_TEMPLATE',
        'DUPLICATE_SPECIALTY_ACCORDION_TEMPLATE'
    ]),

    // Actions
    async saveAsTemplate() {
      if (!this.entityData.name) {
        this.nameError = true
        return this.notify(this.$t('EnterTemplateName') , 'warning')
      }
      let result = await this.ADD_SPECIALTY_ACCORDION_TEMPLATE(this.entityData)
      if (!result) {
        return this.ERROR()
      }
      this.$emit('input' , result)
      this.displaySaveTemplateDialog = false
      this.notify(this.$t('SavedAsTemplate') , 'success')
    },
    async enableEditTemplate() {
      let result = await this.DUPLICATE_SPECIALTY_ACCORDION_TEMPLATE({
        uuid : this.value.uuid,
        specialty_id : this.specialtyId,
        lang : this.lang
      })
      if (!result) {
        return this.ERROR
      }
      this.$emit('input' , result)
      this.displayEnableEditDialog = false

    },

    // Preview
    async selectTemplate(item) {
      let result = await this.SET_SPECIALTY_ACCORDION_TEMPLATE({
        template_id : item.value,
        specialty_id : this.specialtyId,
        type : this.type,
        lang : this.lang
      })
      if (!result) {
        return this.ERROR()
      }
      this.$emit('input' , result)
      this.displaySelectDialog = false
    },

    // Open Dialogs
    openSaveTemplate() {
      this.entityData = {
        uuid : this.value.uuid,
        content : this.value.content,
        lang : this.lang
      }
      this.displaySaveTemplateDialog = true
    },
    openEnableEdit() {
      this.displayEnableEditDialog = true
    },
    async openChangeTemplate() {
      this.previewTemplateId = null
      this.previewTemplate = {}
      if (!this.templatesSelect.length) {
        let result = await this.GET_SPECIALTY_ACCORDION_TEMPLATES(this.type)
        this.templatesSelect = result ? result.templates : []
      }

      this.displaySelectDialog = true
    },


    openDeleteAccordion() {
      this.displayDeleteDialog = true
    },

    openEditTemplate() {
      this.displayEnableEditDialog = false
      this.$emit('edit-template')
    }
  }

}
</script>



<style scoped>

</style>