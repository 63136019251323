<template>
  <div>
    <v-sheet v-if="!items.length" height="100" :color="wsLIGHTCARD" class="d-flex align-center justify-center">
      <ws-button @click="addItem" label="Add"></ws-button>
    </v-sheet>
    <v-hover v-else v-model="totalHover">
      <div>

        <div style="border : 1px solid var(--wsDARKLIGHT); position: relative" class="wsRoundedLight" >
          <div v-if="templateName"
               style="background-color: var(--wsLIGHTCARD); border-bottom: 1px solid var(--wsBACKGROUND)"
               class="px-4 py-3 d-flex justify-space-between">
            <h4 class="wsDARKER text-right" v-if="templateName">{{ templateName }}</h4>
          </div>

          <div v-if="totalHover && templateName"
               style="position : absolute; top:0;bottom: 0;right: 0;left: 0; z-index: 999;"
               :style="`background : ${wsLIGHTCARD + 'bb'}`"
               class="d-flex align-center justify-center"
          >
            <div class="d-flex flex-column justify-center align-center">
              <ws-button
                  @click="openEditTemplate"
                  label="EditTemplate"
                  :disabled="LOADING"
              />
              <ws-button
                  @click="$emit('enable-edit')" outlined class="mt-3"
                  label="wsu_structure.specialty.accordion.enable_edit_template.title"
                  :disabled="LOADING"
              />
            </div>

          </div>

          <template v-if="!noDialog || (noDialog && !selectedItemIndex && selectedItemIndex !== 0) ">

            <draggable
                :disabled="!!templateName && !isTemplate"
                v-model="items"
                :forceFallback="true"
                :empty-insert-threshold="120"
                handle=".alumniDrag"
                v-bind="opt"
                @start="drag = true"
                @end="drag = false;"
                @change="editItems"
            >

              <v-hover #default="{hover}" v-for="(item,index) in items" :key="index + item.uuid">
                <v-sheet
                    @click="openEdit(index)"
                    :color="hover && (!templateName || templateName && isTemplate )? wsLIGHTCARD : 'transparent'"
                    :class="[{pointer: !templateName || (templateName && isTemplate)}]"
                    v-ripple
                >
                  <div
                      class="d-flex justify-space-between align-center px-2"
                  >
                    <div class="d-flex align-center ">
                      <div  :style="`width: ${!templateName ? 75 : 8}px`">
                        <v-icon v-if="hover && !templateName" class="alumniDrag">mdi-drag</v-icon>
                      </div>

                      <slot name="item" :item="item">
                        <h5 :style="!item.title ? `color : ${wsDARKER}` : null" class="wsDARKER py-4">{{ item.title || $t('EnterText') }}</h5>
                      </slot>

                    </div>

                    <div v-if="!templateName || (templateName && isTemplate)" style="width: 200px; height: 40px" class="d-flex justify-end">
                      <v-btn v-if="hover && (!templateName || (templateName && isTemplate) )"
                             @click.stop="duplicateContent(index)"
                             icon :color="wsACCENT"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn v-if="hover && (!templateName || (templateName && isTemplate) )" @click="openDeleteItem(index)" icon :color="wsACCENT">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>


                  </div>
                  <v-divider  style="border-color: var(--wsBACKGROUND)"></v-divider>
                </v-sheet>
              </v-hover>
            </draggable>

            <ws-button
                v-if="!selectedItemIndex && selectedItemIndex !== 0 && !templateName"
                @click="addItem"
                label="Add"
                text
                block
                left-icon="mdi-plus"
                :color="wsACCENT"
            />
          </template>

          <div v-else-if="noDialog && !displayDelete" class="pa-4">

            <div class="d-flex justify-space-between align-center mb-5">
              <h5 @click="cancelEdit" class="wsACCENT pointer linkHover">
                <v-icon :color="wsACCENT">mdi-arrow-left</v-icon>
                {{ $t('Return') }}
              </h5>
              <h5>{{ $t(editText) }} </h5>
            </div>

            <!-- Photo-->
            <h5 class="font-weight-regular wsACCENT mb-2">
              {{ $t('wsu_structure.specialty.alumnis.img') }}
            </h5>
            <slot name="editor" :entityData="entityData">

              <!-- Title-->
              <ws-text-field
                  :label="$t('Title')"
                  :placeholder="$t('EnterTitle')"
                  v-model="entityData.title"
                  class="mb-5"
              />
              <!-- Content -->
              <h5 class="font-weight-regular wsACCENT mb-2">
                {{ $t('Content') }}
              </h5>
              <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7">
                <ws-text-editor
                    v-model="entityData.content"
                    is-public
                />
              </v-sheet>

            </slot>

            <div class="d-flex align-center justify-space-between mt-5">
              <ws-button @click="cancelEdit" outlined :color="wsACCENT" label="Cancel" ></ws-button>
              <ws-button @click="saveEdit" label="Save"></ws-button>
            </div>
          </div>

          <div v-else-if="noDialog && displayDelete" class="pa-4">

            <slot name="delete">
              <h4 class="text-center font-weight-regular py-16 mt-16">{{ $t(confirmDeleteText) }}</h4>
            </slot>

            <div class="d-flex align-center justify-space-between mt-8">
              <ws-button @click="deleteItem" label="Delete"></ws-button>
              <ws-button @click="cancelDelete" outlined :color="wsACCENT" label="Cancel" ></ws-button>
            </div>

          </div>

        </div>

      </div>
    </v-hover>

    <ws-dialog
        v-model="displayDialog"
        :title="$t(isNewElement ? 'AddElement' : 'EditElement')"
        @save="saveEdit"
        fullscreen
        fullscreen-width="600"
        fullscreen-align="right"
        not-centered
        persistent
        show-delete
        :confirm-delete-text="$t(confirmDeleteText)"
        @close="displayDelete ? cancelDelete() : cancelEdit()"
        @delete="deleteItem"
        :close-delete="displayDelete"
        :display-confirm-delete="displayDelete"
    >

      <div v-if="!displayDelete" >

        <slot name="editor" :entityData="entityData">
          <!-- Title-->
          <ws-text-field
              :label="$t('Title')"
              :placeholder="$t('EnterTitle')"
              v-model="entityData.title"
              class="mb-5"
          />
          <!-- Content -->
          <h5 class="font-weight-regular wsACCENT mb-2">
            {{ $t('Content') }}
          </h5>
          <v-sheet min-height="200" :style="`border : 1px solid ${wsDARKLIGHT}`" class="wsRoundedLight pt-3  pb-7">
            <ws-text-editor
                v-model="entityData.content"
                is-public
            />
          </v-sheet>

        </slot>
      </div>

      <div v-else-if="displayDelete" >

        <slot name="delete">
          <h4 class="text-center font-weight-regular py-16 mt-16">{{ $t(confirmDeleteText) }}</h4>
        </slot>

        <div class="d-flex align-center justify-space-between mt-8">
          <ws-button @click="deleteItem" label="Delete"></ws-button>
          <ws-button @click="cancelDelete" outlined :color="wsACCENT" label="Cancel" ></ws-button>
        </div>

      </div>

    </ws-dialog>

    <ws-dialog
        v-if="!isTemplate && displayEditTemplateDialog"
        v-model="displayEditTemplateDialog"
        @save="saveEditTemplate"
        :title="$t('EditTemplate')"
        fullscreen-align="right"
        fullscreen-width="600"
        fullscreen
        not-centered
    >


      <v-sheet :color="wsLIGHTCARD" class="wsRoundedSemi pa-3 mb-5">
        <div class="d-flex justify-space-between">

          <div>
            <h5 class="wsDARKER">{{ $t('TemplateName') }}: <span class="font-weight-regular">{{ templateInfo.name }}</span> </h5>
            <h5 class="wsDARKER ">
              {{ $t('TemplateIsUsedIn') }}: <span class="font-weight-regular"> {{ $tc('programs' , templateInfo.specialties.length) }} </span>
            </h5>
          </div>

          <v-btn @click="displayTemplateDetails = !displayTemplateDetails" icon :color="wsACCENT">
            <v-icon>mdi-chevron-{{ displayTemplateDetails ? 'up' : 'down'   }}</v-icon>
          </v-btn>

        </div>


        <v-expand-transition>
          <div v-if="displayTemplateDetails" class="pt-3">
            <div v-for="item in templateInfo.specialties" :key="item.uuid">
              <h5 class="font-weight-regular wsACCENT">
                <v-icon :color="wsACCENT">mdi-circle-small</v-icon>
                {{ item.name }}
              </h5>
            </div>
          </div>
        </v-expand-transition>



      </v-sheet>

      <description-items-editor
          v-model="value"
          :blankElement="blankElement"
          :confirmDeleteText="confirmDeleteText"
          :editText="editText"
          no-dialog
          is-template
          :lang="lang"
      >
        <template #item="{item}">
          <slot name="item" :item="item"></slot>
        </template>

        <template #editor="{entityData}">
          <slot name="editor" :entityData="entityData" />
        </template>


      </description-items-editor>

    </ws-dialog>


  </div>
</template>

<script>

import draggable from "vuedraggable";
import {mapActions} from "vuex";

export default {
  name: "descriptionItemsEditor",
  components: {
    draggable
  },
  props : {
    value : {
      type : Array,
      default() { return [] }
    },
    blankElement : {
      type : Object,
      default() { return {
        title : '',
        content : [ {"isHtml": true, "content": "" } ]
      }}
    },
    confirmDeleteText : {
      type : String,
      default : 'PleaseConfirmDeleteAction'
    },
    editText : {
      type : String,
      default : 'Edit'
    },
    addItemTrigger : {
      type : Number,
      default : 0
    },
    editTemplateTrigger : {
      type : Number,
    },
    templateName : {
      type : String,
    },
    noDialog : {
      type : Boolean,
      default : false
    },
    isTemplate : {
      type : Boolean,
      default : false
    },
    lang : {
      type : String,
      default : 'ua'
    },
    uuid : {}
  },
  data() {
    return {
      totalHover : false,
      displayDialog : false,
      isNewElement : false,
      items : [],
      selectedItemIndex : null,
      entityData : {},
      displayDelete : false,
      drag : false,
      opt : {
        animation: 200,
        ghostClass: "ghost"
      },
      displayEditTemplateDialog : false,
      templateInfo : {},
      displayTemplateDetails : false,
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value, this.items)) {
          this.items = this.COPY(this.value)
        }
      },
      deep : true
    },
    addItemTrigger() {
      this.addItem()
    },
    editTemplateTrigger() {
      this.openEditTemplate()
    }
  },
  methods : {
    ...mapActions('wsu_structure' , [
        'EDIT_SPECIALTY_ACCORDION_TEMPLATE',
        'GET_SPECIALTY_ACCORDION_TEMPLATE_INFO'
    ]),

    async openEditTemplate() {
      this.templateInfo = await this.GET_SPECIALTY_ACCORDION_TEMPLATE_INFO(this.uuid) || {}
      this.displayTemplateDetails = false
      this.displayEditTemplateDialog = true
    },
    saveEditTemplate() {
      let data = {
        uuid : this.uuid,
        lang : this.lang,
        content : this.value
      }

      let result = this.EDIT_SPECIALTY_ACCORDION_TEMPLATE(data)
      if (!result) {
        return this.ERROR()
      }
      this.displayEditTemplateDialog = false
    },

    duplicateContent(index) {
      this.items.splice(index , 0, this.COPY(this.items[index]))
      this.editItems()
    },

    addItem() {
      this.isNewElement = true
      this.items.push(this.COPY(this.blankElement))
      this.editItems()
      this.openEdit(this.items.length - 1)

    },
    openEdit(index) {
      if (this.templateName) {
        return
      }
      let item = this.COPY(this.items[index])
      item.uuid = this.$uuid.v4
      this.entityData = this.COPY(item)
      this.selectedItemIndex = index
      this.displayDialog = true
    },
    cancelEdit() {
      this.isNewElement = false
      this.selectedItemIndex = null
    },
    saveEdit() {
      this.items[this.selectedItemIndex] = this.COPY(this.entityData)
      this.items = this.COPY(this.items)
      this.editItems()
      this.selectedItemIndex = null
      this.entityData = {}
      this.displayDialog = false
      this.isNewElement = false
    },
    editItems() {
      this.$emit('input', this.COPY(this.items))
    },

    openDeleteItem(index) {
      this.isNewElement = false
      this.selectedItemIndex = index
      this.displayDelete = true
    },
    cancelDelete() {
      this.isNewElement = false
      this.selectedItemIndex = null
      this.displayDelete = false
    },
    deleteItem() {
      this.isNewElement = false
      this.items.splice(this.selectedItemIndex , 1)
      this.editItems()
      this.cancelDelete()
      this.displayDialog = false
    },

  },
  mounted() {
    this.$emit('set-add-question' , this.addItem)
    if (this.value) {
      this.items = this.COPY(this.value)
    }
  }
}
</script>


<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>